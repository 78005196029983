import * as React from 'react';
import hash from 'object-hash';
import { useDebouncedCallback } from 'use-debounce';
import { updateForm, getFromForm } from '@aventus/formio';
import { updateRiskFromQuestions } from '../services/update-risk-from-questions';
import { percentageOfAnsweredQuestions } from '../services/percentage-of-answered-questions';
import { useTrack } from '@aventus/application-tracking';
import {
  IntelligentQuoteRequest,
  IntelligentQuoteResponse,
  QuoteState,
  QuoteRateableState,
  IntelligentQuotePage,
  QuoteBundle,
  Quote,
  PricingSet,
  MtaInformation,
  TobesState,
  IRisk
} from '@aventus/platform';
import { InvalidProductError, MTAError } from '@aventus/errors';

export interface IntelligentQuotePageState {
  questionPage: IntelligentQuotePage;
  quoteState: QuoteState;
  quoteRateableState: QuoteRateableState;
}

export function useIntelligentQuoting(
  isAutoNext?: boolean,
  quoteId?: string,
  partnerId?: string
): UseIntelligentQuoting {
  /**
  Intelligent Quoting
  Data Stores
  */

  const { track } = useTrack();

  // A page is a set of questions returned from the API. This collection
  // of pages represents the progress a user has made through
  // the quote flow.

  const pages = React.useRef<IntelligentQuotePageState[]>([]);

  // When a user changes the answer to a previously confirmed page,
  // all following pages are also unconfirmed. This state will temporarily
  // hold pages that are unconfirmed and deleted, incase they need to
  // popped back onto the pages stack.

  const unconfirmedPages = React.useRef<IntelligentQuotePageState[]>([]);

  // This pointer represents the active page, from
  // the collection of pages available.
  // TODO -> useRef

  const [pagePointer, setPagePointer] = React.useState<number>(-1);

  // This risk state is the encrypted string that is returned from the
  // API that contains user specific data in a protected way. It is for the
  // API to keep track of the user across multiple anonymous API calls.
  // (The risk state is referred to as the tobesState by the API -- it is
  // called risk state here just to help with naming consistent.)

  const [riskState, setRiskState] = React.useState<TobesState>('');

  // This collection of risks tracks, or mirrors, the pages. Each
  // page contributes towards updating a risk object. This array
  // tracks the state of that risk object as the user progresses through
  // each page. So, for example, the risk at array position 2, represents
  // the state of the risk when the user filled out the 3rd page's
  // questions.
  // TODO -> useRef

  const [risks, setRisks] = React.useState<Array<IRisk>>([]);
  const [originalRisk, setOriginalRisk] = React.useState<IRisk>([]);

  const unconfirmedRisks = React.useRef<Array<IRisk>>([]);

  // This risk object is simply the risk associated
  // with the currently active page.
  // TODO -> rename to `currentRisk` ??

  const risk = risks[pagePointer || 0];

  // The risk hash is specific to this application code. It's
  // simply a hash of the risk, to be able to identify
  // when a value in the risk has changed, and a new API call
  // is required.
  // In other words, this is designed so the API hooks use the risk
  // hash as an observable, and when the value changes then that
  // API call can be triggered.

  const [riskHash, setRiskHash] = React.useState<string>('');
  const [riskHashes, setRiskHashes] = React.useState<string[]>([]);
  const [customizationNext, setCustomizationNext] = React.useState<boolean>(false);

  // This flag identifies whether or not the user can
  // save a quote while in a partial state.

  const [allowSaveQuote, setAllowSaveQuote] = React.useState<boolean>(false);

  const [originator, setOriginator] = React.useState<string>('');

  const [emptyPages, setEmptyPages] = React.useState<number[]>([]);

  /**
  Intelligent Quoting
  Functions
  */

  function addRisk(risk: IRisk) {
    setRisks([...risks, risk]);
  }

  function setRisk(risk: IRisk) {
    const _risks = [...risks];
    _risks[pagePointer] = risk;
    setRisks(_risks);
  }

  function _setRisk(
    riskAddress: string,
    newValue: any,
    isCollection?: boolean,
    isValid: boolean = true
  ) {
    const isRoot = riskAddress === '*';

    // TODO
    // This needs to be cleaned up better.
    // 1. The existing updateForm should be able to the handle
    //    a root update command object.
    // 2. I'm not sure I like the new idea of Formio having this
    //    logic hidden away.

    // If the riskAddress is *, then we dont need any
    // update commands and we can just swap out the whole
    // object. This should only be used for initialisation.

    let updatedRisk: any;

    if (isRoot) {
      updatedRisk = newValue;
    } else {
      const currentValue = getFromForm(risk, riskAddress);

      const updateCommand =
        currentValue !== null &&
          typeof currentValue === 'object' &&
          !isCollection &&
          newValue !== null
          ? { $merge: newValue }
          : { $set: newValue };

      track({
        event: 'aventus.quote.riskUpdated',
        quoteId: quoteId,
        partnerId: partnerId,
        riskPath: riskAddress,
        oldValue: currentValue,
        newValue: newValue
      });

      updatedRisk = updateForm(risk, riskAddress, updateCommand);
    }

    // If we're editing a question in a page that has
    // already been confirmed, then we need to remove all other
    // confirmations that came after this one.
    // The simplest way is to remove the whole pages
    // from the pages array.

    if (isAnAlreadyConfirmedPage) {
      removeConfirmedPages(updatedRisk);
    } else {
      setRisk(updatedRisk);
    }

    const question = pages.current[pagePointer]?.questionPage?.questions.find(question => question.riskPath === riskAddress)

    if (quoteRateableState === 'Rateable' && question?.ratable !== false && isValid) {
      _setRiskHashDebounced(updatedRisk);
    }
  }

  function _setRiskHash(directRisk?: IRisk) {
    const newHash: string = hash(directRisk || risk);
    const isHashNew: boolean = riskHashes[pagePointer] !== newHash;

    if (isHashNew) {
      const _riskHashes = [...riskHashes];
      _riskHashes[pagePointer] = newHash;
      setRiskHashes(_riskHashes);
      setRiskHash(newHash);
    }

    return isHashNew;
  }

  const [_setRiskHashDebounced] = useDebouncedCallback(_setRiskHash, 750);

  // We know that updating the pages Ref will allow us to either add a new page,
  // or remove a specific page

  // There are two main scenarios where we would want to do this.

  // The first scenario is when the user is going forward through the quote form requiring
  // a new page to be added.

  function addPage(page: IntelligentQuotePageState) {
    // Avoid adding page if its already been added
    const pageAlreadyAdded = !!pages.current.find(x => x.questionPage.title === page.questionPage.title && x.questionPage.questions.length === page.questionPage.questions.length);

    if (quoteState !== 'Buyable' && !pageAlreadyAdded) {
      setPagePointer(pagePointer + 1);
      pages.current = [...pages.current, page];
    }
  }

  // The second scenario is when the user has gone back one or more pages,
  // and edits an answer. If -- and only if -- the user edits, then we need
  // to invalidate all the pages that follow it. This is because each page
  // is based on the specific answers of the previous page. So if a previous page
  // has any answers changed, then we need to recalculate the next page, and so on.
  // The easiest way to do this is to just remove all of the following pages
  // and let them be recalculated organically by the rest of the logic in this
  // function.

  function removeConfirmedPages(updatedRisk: IRisk) {
    // We need to take off all the following
    // pages from the current, as those are now
    // unconfirmed.

    const _pages = [...pages.current];
    const _unconfirmedPages = _pages.splice(pagePointer + 1);
    pages.current = _pages;

    // Store the unconfirmed pages, in case
    // we need to pop them back onto the stack.

    unconfirmedPages.current = _unconfirmedPages;

    // We need to take off all the following
    // risks from the current, as those are now
    // unconfirmed.

    const _risks = [...risks];
    const _unconfirmedRisks = _risks.splice(pagePointer + 1);
    _risks[pagePointer] = updatedRisk;
    setRisks(_risks);

    // Store the unconfirmed risks, in case
    // we need to pop them back onto the stack

    unconfirmedRisks.current = _unconfirmedRisks;

    // We can safely say the quote is not
    // buyable, simply by the fact that we are unconfirming
    // certain pages.
    // The QuoteState and QuoteRateableState is automatically updated based on the current page
  }

  /**
   * Page Pointers
   * -------------
   */

  // The pagePointer keeps track of the active page the user is on.

  const isFirstPage: boolean = pagePointer === 0;
  const isLastPage: boolean = pagePointer === pages.current.length - 1;
  const isAnAlreadyConfirmedPage: boolean =
    pagePointer < pages.current.length - 1;

  // These functions serve as semantic wrappers over the internal
  // mechanics of intelligent quoting.

  function nextPage() {
    if (pagePointer !== pages.current.length - 1) {
      // If we're on any page before the last, then we
      // can safely assume the user has navigated backwards
      // WITHOUT updating any answers.
      // In this mode we're just moving through the existing pages
      // in the collection. We don't want to trigger off
      // any API calls in this scenario.
      setPagePointer(pagePointer + 1);
    } else {
      // If we are on the last page, and this function
      // was triggered, then we can assume that the page validation
      // has passed and we want to get a new page.

      // The only exception is when this is triggered
      // on the last possible page, of which the possibilities are:
      // * Product with customisation
      // * Product without
      // In this case we want to confirm the risk and get a finished
      // quote object.

      if (quoteState === 'Buyable') {
        setConfirm(true);
        _setRiskHash({ ...risk, ...{ confirmed: true } });
      } else {
        const isHashNew = _setRiskHash();

        // if the current page is rateble, then navigate to the next page
        if (quoteRateableState === 'Rateable') {
          if (unconfirmedPages.current.length > 0) {
            // we also need to make sure to clear the unconfirmedPages
            // and unconfirmedRisks, to avoid any incorrect state assumptions in future flows.
            unconfirmedPages.current = [];
            unconfirmedRisks.current = [];
          }
          setCustomizationNext(true);
        }
        else if (!isHashNew) {
          // If the hash is false, it means
          // that we are in a previously known state.
          // Since we are the last page, then that
          // means we would have unconfirmed some pages.
          // If we do in fact have unconfirmed pages in our store
          // then we can safely pop them back and re-hit next.

          if (unconfirmedPages.current.length > 0) {
            const _pages = [...pages.current, ...unconfirmedPages.current];
            pages.current = _pages;

            const _risks = [...risks, ...unconfirmedRisks.current];
            setRisks(_risks);

            // we also need to make sure to clear the unconfirmedPages
            // and unconfirmedRisks, since we have just used them. This is to avoid any
            // incorrect state assumptions in future flows.

            unconfirmedPages.current = [];
            unconfirmedRisks.current = [];

            // With our unconfirmed pages now re-confirmed,
            // we can navigate back to the next page.
            nextPage();
          }
        }
      }
    }
  }

  function previousPage() {
    if (pagePointer !== 0) {
      setPagePointer(pagePointer - 1);
    }
  }

  /**
   * The Quote State
   */

  // This value keeps track of when a specific risk is now buyable,
  // meaning the risk that's built up so far can be rated, so given a quote
  // and price.
  // In Symphony, if a risk is buyable and the specific Insurance product supports
  // post-quote updates then it is a hook to render the customisation page.

  const quoteState = pages.current[pagePointer || 0]?.quoteState || 'NotBuyable';
  const quoteRateableState = pages.current[pagePointer || 0]?.quoteRateableState || 'NotRateable';

  const [quote, setQuote] = React.useState<Quote | undefined>(undefined);
  const [quotePricing, setQuotePricing] = React.useState<
    PricingSet | undefined
  >(undefined);
  const [adjustPricing, setAdjustPricing] = React.useState<
    MtaInformation | undefined
  >(undefined);

  const [confirm, setConfirm] = React.useState<boolean>(false);
  const [confirmedQuoteId, setConfirmedQuoteId] = React.useState<
    string | undefined
  >(undefined);

  const [shouldSaveQuote, setShouldSaveQuote] = React.useState<boolean>(false);
  const [savedQuoteId, setSavedQuoteId] = React.useState<string | undefined>(
    undefined
  );

  function saveQuote() {
    setShouldSaveQuote(true);
  }

  const isCustomisationPage =
    quote && quoteRateableState === 'Rateable';

  function handleStart(
    intelligentQuoteRequest: IntelligentQuoteRequest,
    _originator: string
  ) {
    _setRisk('*', intelligentQuoteRequest.risk);
    setRiskState(intelligentQuoteRequest.tobesState);
    _setRiskHash(intelligentQuoteRequest.risk);
    setOriginator(_originator);
    intelligentQuoteRequest.enableSaveQuotes && setAllowSaveQuote(true);
  }

  function handleNext(intelligentQuoteResponse: IntelligentQuoteResponse) {
    // First, we need to update the risk we've got with any of the known answers
    // returned with each question.

    const updatedRisk: IRisk = intelligentQuoteResponse.questionPage.questions
      ? updateRiskFromQuestions(
        intelligentQuoteResponse.risk,
        intelligentQuoteResponse.questionPage.questions
      )
      : intelligentQuoteResponse.risk;

    // If this flag is switched on, then we'll attempt
    // to automatically go through the question set by automatically
    // going through the question pages. We can only go next if
    // all questions on a given page are answered.

    // It is very important we trigger this off before
    // updating the risk values, as the next trigger works
    // by hashing the saved risk, which would be identical
    // if triggered after.

    if (isAutoNext) {
      const percentageAnswered: number = percentageOfAnsweredQuestions(
        intelligentQuoteResponse.questionPage.questions, updatedRisk
      );

      if (percentageAnswered === 100) {
        _setRiskHash(updatedRisk);
      }
    }

    if (!intelligentQuoteResponse.questionPage.questions.length) {
      // If there are no questions, then we move to the next page
      _setRiskHash(updatedRisk);

      if (!emptyPages.includes(pagePointer)) {
        setEmptyPages([...emptyPages, pagePointer + 1]);
      }
    }

    // and update all the relevant state stores
    // with the new risk and the page.

    const questionPageState: IntelligentQuotePageState = {
      questionPage: intelligentQuoteResponse.questionPage,
      quoteState: intelligentQuoteResponse.quoteState,
      quoteRateableState: intelligentQuoteResponse.metadata.rateableState
    };

    setCustomizationNext(false);
    _setRisk('*', updatedRisk);
    addRisk(updatedRisk);
    setOriginalRisk(intelligentQuoteResponse.originalRisk)
    setRiskState(intelligentQuoteResponse.tobesState);
    addPage(questionPageState);
  }

  function handleNextQuote(intelligentQuoteBundle: QuoteBundle) {
    setQuote(intelligentQuoteBundle.requestedQuote);
    setQuotePricing(intelligentQuoteBundle.requestedQuotePricing);
    setAdjustPricing(intelligentQuoteBundle.mtaInfo);

    // And we're done!

    if (intelligentQuoteBundle.requestedQuote.quoteStatus === 'Confirmed') {
      setConfirmedQuoteId(intelligentQuoteBundle.requestedQuote.id);
    }
  }

  function handleSavedQuote(intelligentQuoteBundle: QuoteBundle) {
    setShouldSaveQuote(false);
    setSavedQuoteId(intelligentQuoteBundle.requestedQuote.id);
  }

  function previousPageIsEmpty() {
    return emptyPages.includes(pagePointer - 1);
  }

  return {
    risk,
    risks: risks,
    originalRisk,
    setRisk: _setRisk,
    addRisk,
    setRiskHash,

    riskHash,
    riskState,
    setRiskState,

    page: pages.current[pagePointer],
    pages: pages.current,
    addPage,

    pagePointer,
    nextPage,
    previousPage,
    setPagePointer,
    isFirstPage,
    isLastPage,
    isCustomisationPage,

    quoteState,
    quoteRateableState,
    allowSaveQuote,
    quote,
    setQuote,
    quoteId,
    partnerId,
    quotePricing,
    setQuotePricing,
    adjustPricing,

    confirm,
    confirmedQuoteId,
    setConfirmedQuoteId,

    handleStart,
    handleNext,
    handleNextQuote,

    shouldSaveQuote,
    saveQuote,
    savedQuoteId,
    handleSavedQuote,
    originator,
    customizationNext,
    setCustomizationNext,
    previousPageIsEmpty
  };
}

export interface UseIntelligentQuoting {
  risk: IRisk;
  originalRisk: IRisk;
  risks: Array<IRisk>;
  setRisk: (riskAddress: string, newValue: any, isCollection: boolean, isValid?: boolean) => void;
  addRisk: (risk: IRisk) => void;
  riskHash: string;
  riskState: TobesState;
  setRiskState: (riskState: TobesState) => void;
  setRiskHash: (riskHash: string) => void;
  page: IntelligentQuotePageState;
  pages: IntelligentQuotePageState[];
  addPage: (page: IntelligentQuotePageState) => void;
  pagePointer: number;
  nextPage: () => void;
  previousPage: () => void;
  setPagePointer: (index: any) => void;
  isFirstPage: boolean;
  isLastPage: boolean;
  isCustomisationPage: boolean | undefined;
  quoteState: QuoteState;
  quoteRateableState: QuoteRateableState;
  allowSaveQuote: boolean;
  quote: Quote | undefined;
  quoteId?: string;
  partnerId?: string;
  setQuote: (quote: Quote) => void;
  quotePricing: PricingSet | undefined;
  adjustPricing: MtaInformation | undefined;
  setQuotePricing: (quotePricing: PricingSet) => void;
  confirm: boolean;
  confirmedQuoteId: string | undefined;
  setConfirmedQuoteId: (quoteId: string) => void;
  handleStart: (
    intelligentQuoteRequest: IntelligentQuoteRequest,
    _originator: string
  ) => void;
  handleNext: (intelligentQuoteResponse: IntelligentQuoteResponse) => void;
  handleNextQuote: (intelligentQuoteBundle: QuoteBundle) => void;
  shouldSaveQuote: boolean;
  saveQuote: () => void;
  savedQuoteId: string | undefined;
  handleSavedQuote: (intelligentQuoteBundle: QuoteBundle) => void;
  isWorking?: boolean;
  originator: string;
  customizationNext: boolean;
  setCustomizationNext: (customizationNext: boolean) => void;
  invalidProductError?: InvalidProductError;
  mtaError?: MTAError;
  previousPageIsEmpty: () => boolean;
}
